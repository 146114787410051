var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agent-collaboration-network"},[_c('div',{staticClass:"container fluid"},[_c('h1',{staticClass:"m-4"},[_vm._v("Agent Collaboration Network")]),_c('div',{staticClass:"d-flex justify-content-end mb-2"},[_c('fd-button',{staticClass:"medium main",on:{"click":function($event){return _vm.$router.push({ name: 'ACNPublicJobs' })}}},[_vm._v(" Public Job "),_c('i',{staticClass:"fas fa-chevron-right ml-1"})])],1),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.tableColumns,"rows":_vm.tableData,"totalRows":_vm.tablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false,
          initialSortBy: { field: 'updatedAt', type: 'desc' }
        }},on:{"on-row-click":function (e) {
            _vm.toACNDetail(e.row.id);
          },"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'property')?_c('span',[_c('property-summary',{attrs:{"property":props.row.acnQuest.listing.property,"purpose":false,"address":""}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no active ACN at the moment."}})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }