<template>
  <div class="agent-collaboration-network">
    <div class="container fluid">
      <h1 class="m-4">Agent Collaboration Network</h1>

      <div class="d-flex justify-content-end mb-2">
        <fd-button
          class="medium main"
          @click="$router.push({ name: 'ACNPublicJobs' })"
        >
          Public Job <i class="fas fa-chevron-right ml-1"></i>
        </fd-button>
      </div>

      <div class="card">
        <vue-good-table
          mode="remote"
          @on-row-click="
            (e) => {
              toACNDetail(e.row.id);
            }
          "
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="tableColumns"
          :rows="tableData"
          :totalRows="tablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false,
            initialSortBy: { field: 'updatedAt', type: 'desc' }
          }"
        >
          <div slot="emptystate">
            <no-data message="There is no active ACN at the moment."></no-data>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'property'">
              <property-summary
                :property="props.row.acnQuest.listing.property"
                :purpose="false"
                address
              ></property-summary>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { acn as acnAPI } from "@/api";

export default {
  components: {
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      tableData: [],
      tableColumns: [
        {
          label: "Property",
          field: "property"
        },
        {
          label: "Updated At",
          field: (row) => this.$dayjs(row.updatedAt).format("DD MMM YYYY")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      tablePagination: {
        total: 0
      },

      filter: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getACNGroups();
    },
    toACNDetail(id) {
      this.$router.push({
        name: "ACNDetail",
        params: {
          id: id
        }
      });
    },

    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getACNGroups();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getACNGroups();
    },
    // ============================== API RELATED ==============================
    async getACNGroups() {
      this.$store.commit("setIsLoading", true);
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await acnAPI.getGroups({
          queries: filteredParam,
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.tableData = this._.cloneDeep(data.data);
        this.tablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch ACN groups. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
